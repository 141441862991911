
import { defineComponent, nextTick, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import JwtService, {
  getExpiresAt,
  getOrganization,
  getRehab,
  getUser,
  saveUser,
} from "./core/services/JwtService";
import ApiService from "./core/services/ApiService";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoaded = ref(false);

    onMounted(async () => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      //store.commit(Mutations.PURGE_AUTH);
      //saveUser(null);
      //TODO AUTH
      const user = getUser();
      if (user) {
        store.commit(Mutations.SET_USER, user);
        ApiService.setUserHeader(JSON.stringify(user.profile));
      }
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      isLoaded.value = true;
      nextTick(() => {
        initializeComponents();
      });
      //Timer for logout from EMR/Expire check
      setInterval(async () => checkToken(), 180000);

      document.body.onfocus = onfocus;
    });

    async function onfocus() {
      await checkToken();
    }

    async function checkToken() {
      // const time = getExpiresAt();
      // if (time && Date.now() / 1000 >= Number(time)) {
      //   store.commit(Mutations.PURGE_AUTH);
      //   saveUser(null);
      // }
      await store.dispatch(Actions.VERIFY_AUTH, {
        api_token: JwtService.getToken(),
      });
      if (!JwtService.getToken()) {
        router.push({ path: "/open-id-login" });
      }
    }

    return {
      isLoaded,
    };
  },
});
