enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_USER_MANAGER = "setUserManager",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
}

enum ClaimStatusList {
  Submitted = 0,
  SubmissionFailed = 1,
  PendingSubmission = 2,
  Resubmitted = 3,
  OnHold = 4,
  Closed = 5,
  Voided = 6,
  Created = 7,
  VoidFailed = 8,
  Transferred = 9
}

enum EncounterStatusList {
  On_Hold = "OnHold",
  Ready_To_Bill = "ReadyToBill", 
  Billed = "Billed",
  Voided = "Voided",
  Needs_Attention = "NeedsAttention",
  Bill_Patient = "BillPatient",
  Submitted_To_Clearinghouse = "SubmittedToClearinghouse",
}

enum ReviewType {
  Patient = "patient",
  Claim = "claim",
}

export { Actions, Mutations, ClaimStatusList, EncounterStatusList, ReviewType };
