
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  props: ["modelValue"],
  emits: ["update:modelValue"],
  methods: {
    onChange(modelData) {
      if (modelData) {
        this.$emit("update:modelValue", modelData?.hours);
      } else {
        this.$emit("update:modelValue", null);
      }
    },
  },
  setup(props) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    let hour = props.modelValue;
    if (!hour) {
      hour = 0;
    }
    const time = ref({
      hours: hour,
      minutes: 0,
    });

    const flow = ref(["hours"]);

    watch(
      () => props.modelValue,
      () => {
        if (props.modelValue) {
          if (!time.value) {
            time.value = {
              hours: 0,
              minutes: 0,
            };
          }
          time.value.hours = props.modelValue;
        } else {
          if (time.value) time.value.hours = 0;
        }
      }
    );

    return { time, flow };
  },
});
