import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/homePage",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/homePage",
        name: "homePage",
        component: () => import("@/views/pages/MenuPages/HomePage.vue"),
      },
      {
        path: "/serviceManagement/",
        name: "Service Management",
        component: () => import("@/views/pages/MenuPages/ServiceManagementRouterView.vue"),
        children: [
          {
            path: "",
            name: "Service Management",
            component: () => import("@/views/pages/MenuPages/ServiceManagement.vue"),
          },
          {
            path: "encounterNew",
            component: () => import("@/views/pages/Encounter/EncounterPage.vue"),
            props: true,
            children: [
              {
                path: "/facilityPageAfterEncounter",
                component: () => import("@/views/pages/Settings/FacilityPage.vue"),
                props: true,
                children: [
                  {
                    path: "/encounter/:encounterId",
                    component: () => import("@/views/pages/Encounter/EncounterPage.vue"),
                    props: true,
                  },
                ],
              },
            ],
          },
          {
            path: "encounter/:encounterId",
            component: () => import("@/views/pages/Encounter/EncounterPage.vue"),
            props: true,
            children: [
              {
                path: "/facilityPageAfterEncounter",
                component: () => import("@/views/pages/Settings/FacilityPage.vue"),
                props: true,
                children: [
                  {
                    path: "/encounter/:encounterId",
                    component: () => import("@/views/pages/Encounter/EncounterPage.vue"),
                    props: true,
                  },
                ],
              },
              {
                path: "/providerPageAfterEncounter",
                component: () => import("@/views/pages/Settings/ProviderPage.vue"),
                props: true,
                children: [
                  {
                    name: "encounter",
                    path: "/encounter/:encounterId",
                    component: () =>
                      import("@/views/pages/Encounter/EncounterPage.vue"),
                    props: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/patientAccounts",
        name: "Patient Accounts",
        component: () => import("@/views/pages/MenuPages/PatientAccountsRouterView.vue"),
        children: [
          {
            path: "",
            name: "Patient Accounts",
            component: () => import("@/views/pages/MenuPages/PatientAccounts.vue"),
          },
          {
            path: "patient/:patientId/patientInsurance/:patientPayerId?",
            component: () =>
              import("@/modules/patient/PatientInsuranceComponentV2.vue"),
            props: true,
          },
          {
            path: "patient/:patientId/guarantor/:guarantorId?",
            component: () => import("@/modules/guarantors/Guarantor.vue"),
            props: true,
          },
          {
            path: "patient/:patientId/superbill",
            component: () => import("@/views/pages/MenuPages/Superbill.vue"),
            props: true,
          },
          {
            path: "patient/:patientId",
            component: () => import("@/views/pages/Patient/PatientPage.vue"),
            props: true,
            children: [
              {
                path: "patientDemographics",
                name: "patientDemographics",
                component: () =>
                  import("@/modules/patient/PatientDemographicsComponentV2.vue"),
                props: true,
              },
              {
                path: "patientInsurances",
                component: () =>
                  import("@/modules/patient/PatientInsuranceListComponent.vue"),
                props: true,
              },
              {
                path: "patientTransactions",
                component: () => import("@/modules/patient/Transactions.vue"),
                props: true,
              },
              {
                path: "serviceHistory",
                component: () => import("@/modules/patient/ServiceHistoryV2.vue"),
                props: true,
              },
              {
                path: "patientStatements",
                component: () => import("@/modules/patient/Statements.vue"),
                props: true,
              },
              {
                path: "unappliedCredit",
                component: () => import("@/modules/patient/UnappliedCredit.vue"),
                props: true,
              },
              {
                path: "guarantorList",
                component: () => import("@/modules/guarantors/GuarantorsList.vue"),
                props: true,
              },
              {
                path: "notes",
                component: () => import("@/modules/patient/PatientNotes.vue"),
                props: true,
              },
            ],
          }
        ],
      },
      {
        path: "/Payments/",
        name: "Payments",
        component: () => import("@/views/pages/MenuPages/PaymentsRouterView.vue"),
        children: [
          {
            path: "",
            redirect: "/Payments/PatientTransactionsList",
            component: () => import("@/views/pages/MenuPages/PaymentsPage.vue"),
            children: [
              {
                path: "PatientTransactionsList",
                name: "PatientTransactionsList",
                component: () => import("@/views/pages/Payments/PatientTransactionsList.vue"),
              },
              {
                path: "InsuranceTransactionsList",
                name: "InsuranceTransactionsList",
                component: () => import("@/views/pages/Payments/InsuranceTransactionsList.vue"),
              },
              {
                path: "PaymentPlanList",
                component: () => import("@/views/pages/PaymentPlan/PaymentPlanList.vue"),
                props: true,
                children: [],
              },
              // {
              //   path: "PayerPays",
              //   name: "PayerPays",
              //   component: () => import("@/views/pages/Payments/PayerPays.vue"),
              // },
              // {
              //   path: "PatientPays",
              //   name: "PatientPays",
              //   component: () => import("@/views/pages/Payments/PatientPays.vue"),
              // },
              {
                path: "UnappliedCredits",
                name: "UnappliedCredits",
                component: () => import("@/views/pages/Payments/UnappliedCredits.vue"),
              },
            ],
          },
          {
            path: "patientPayment",
            component: () => import("@/views/pages/Payments/PatientPayment.vue"),
            props: true,
            children: [],
          },
          {
            path: "/patientPayment/:paymentId",
            component: () => import("@/views/pages/Payments/PatientPayment.vue"),
            props: true,
            children: [],
          },
          {
            path: "patientRefund",
            component: () => import("@/views/pages/Payments/PatientRefund.vue"),
            props: true,
            children: [],
          },
          {
            path: "patientRefund/:paymentId",
            component: () => import("@/views/pages/Payments/PatientRefund.vue"),
            props: true,
            children: [],
          },
          {
            path: "insurancePayment",
            component: () => import("@/views/pages/Payments/InsurancePayment.vue"),
            props: true,
            children: [],
          },
          {
            path: "/insurancePayment/:paymentId",
            component: () => import("@/views/pages/Payments/InsurancePayment.vue"),
            props: true,
            children: [],
          },
          {
            path: "paymentPlan",
            component: () => import("@/views/pages/PaymentPlan/PaymentPlan.vue"),
            props: true,
            children: [],
          },
          {
            path: "/paymentPlan/:paymentPlanId",
            component: () => import("@/views/pages/PaymentPlan/PaymentPlan.vue"),
            props: true,
            children: [],
          },
        ],
      },
      {
        path: "/claim/:claimId",
        component: () => import("@/views/pages/Claim/ClaimPageV2.vue"),
        //redirect: "/claimSummary",
        props: true,
        children: [
          // {
          //   path: "",
          //   redirect: "claimSummary",
          // },
          {
            path: "effectivePayers",
            component: () => import("@/modules/claims/EffectivePayers.vue"),
            props: true,
          },
          {
            path: "services",
            name: "services",
            component: () => import("@/modules/claims/Services.vue"),
            props: true,
          },
          {
            path: "professinalServices",
            name: "professinalServices",
            component: () =>
              import("@/modules/claims/ProfessionalServices.vue"),
            props: true,
          },
          {
            path: "claimDetails",
            name: "claimDetails",
            component: () => import("@/modules/claims/ClaimComponentV2.vue"),
            props: true,
          },
          {
            path: "claimSummary",
            component: () => import("@/views/pages/Claim/ClaimSummary.vue"),
            props: true,
          },
          {
            path: "facilityClaimDetails",
            name: "facilityClaimDetails",
            component: () =>
              import("@/modules/claims/FacilityClaimComponent.vue"),
            props: true,
          },
          {
            path: "professionalClaimDetails",
            name: "professionalClaimDetails",
            component: () =>
              import("@/modules/claims/ProfessionalClaimComponent.vue"),
            props: true,
          },
          {
            path: "waystarForm",
            name: "waystarForm",
            component: () =>
              import("@/modules/waystarForm/WaystarFormComponent.vue"),
            props: true,
          },
          {
            path: "submissionHistory",
            name: "submissionHistory",
            component: () => import("@/modules/claims/SubmissionHistory.vue"),
            props: true,
          },
          {
            path: "notes",
            name: "notes",
            component: () => import("@/modules/claims/ClaimNotesComponent.vue"),
            props: true,
          },
        ],
      },
      {
        path: "/facilityPageAfterEncounter",
        name: "facilityPageAfterEncounter",
        component: () => import("@/views/pages/Settings/FacilityPage.vue"),
        props: true,
      },
      {
        path: "/facilityPage",
        name: "facilityPage",
        component: () => import("@/views/pages/Settings/FacilityPage.vue"),
        props: true,
      },
      {
        path: "/providerPageAfterEncounter",
        name: "providerPageAfterEncounter",
        component: () => import("@/views/pages/Settings/ProviderPage.vue"),
        props: true,
      },
      {
        path: "/providerPage/:providerId/",
        name: "providerPage",
        component: () => import("@/views/pages/Settings/ProviderPage.vue"),
        props: true,
      },
      {
        path: "/referringProviderPage",
        name: "referringProviderPage",
        component: () =>
          import("@/views/pages/Settings/ReferringProviderPage.vue"),
        props: true,
      },
      {
        path: "/Settings",
        name: "Settings",
        redirect: "/Settings/providersPage",
        component: () => import("@/views/pages/Settings/SettingsPage.vue"),
        children: [
          {
            path: "providersPage",
            name: "providersPage",
            component: () => import("@/views/pages/Settings/ProvidersPage.vue"),
          },
          {
            path: "facilitiesPage",
            name: "facilitiesPage",
            component: () =>
              import("@/views/pages/Settings/FacilitiesPage.vue"),
          },
          {
            path: "referringProvidersPage",
            name: "referringProvidersPage",
            component: () =>
              import("@/views/pages/Settings/ReferringProvidersPage.vue"),
          },
          {
            path: "dropdownsPage",
            name: "dropdownsPage",
            component: () => import("@/views/pages/Settings/DropdownsPage.vue"),
          },
          {
            path: "codeMasterPage",
            component: () =>
              import("@/views/pages/Settings/CodeMarterPage.vue"),
          },
          {
            name: "insuranceCompanyPage",
            path: "insuranceCompanyPage",
            component: () =>
              import("@/views/pages/Settings/InsuranceCompanyPage.vue"),
            props: true,
          },
          {
            name: "insuranceCompanyAddNewPage",
            path: "/insuranceCompanyAddNewPage/",
            component: () =>
              import("@/views/pages/Settings/InsuranceCompanyAddPage.vue"),
            props: true,
          },
          {
            name: "insuranceCompanyAddPage",
            path: "/insuranceCompanyAddPage/:insuranceCompanyId",
            component: () =>
              import("@/views/pages/Settings/InsuranceCompanyAddPage.vue"),
            props: true,
          },
          {
            path: "statementSettings",
            component: () =>
              import("@/views/pages/Settings/StatementSettings.vue"),
          },
          {
            path: "usersPage",
            component: () => import("@/views/pages/Settings/UsersPage.vue"),
          },
          {
            path: "serviceMessages",
            component: () =>
              import("@/views/pages/Settings/ServiceMessages.vue"),
          },
        ],
      },
      {
        path: "/settings/serviceMasterPage",
        name: "serviceMasterPage",
        component: () => import("@/views/pages/Settings/ServiceMasterPage.vue"),
        props: true,
      },
      {
        path: "/settings/claimSettingsPage",
        name: "claimSettingsPage",
        component: () => import("@/views/pages/Settings/ClaimSettingsPage.vue"),
        props: true,
      },
      {
        path: "/settings/clearinghouseSettingsPage",
        name: "clearinghouseSettingsPage",
        component: () =>
          import("@/views/pages/Settings/ClearinghouseSettingsPage.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/",
    redirect: "/claimPage",
    component: () => import("@/layout/Layout.vue"),
    props: true,
    children: [
      {
        path: "/claimPage",
        name: "claimPage",
        component: () => import("@/views/pages/OldPages/ClaimPage.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/",
    redirect: "/ClaimManagement",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/ClaimManagement",
        name: "Claim Management",
        component: () => import("@/views/pages/MenuPages/ClaimManagement.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/ClaimSubmission",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/ClaimSubmission",
        name: "ClaimSubmission",
        component: () => import("@/views/pages/MenuPages/ClaimSubmission.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/PaymentPosting",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/PaymentPosting",
        name: "PaymentPosting",
        component: () => import("@/views/pages/MenuPages/PaymentPosting.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/Statements",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/Statements",
        name: "Statements",
        component: () => import("@/views/pages/MenuPages/Statements.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/Statement",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/Statement",
        name: "Statement",
        component: () => import("@/modules/statement/StatementPage.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/UnappliedCredit",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/UnappliedCredit",
        name: "UnappliedCredit",
        component: () => import("@/views/pages/MenuPages/UnappliedCredit.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/organizationsList",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/organizationsList",
        name: "organizationsList",
        component: () =>
          import("@/views/pages/OldPages/OrganizationsListPage.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/organizations",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/organizations",
        name: "organizations",
        component: () => import("@/views/pages/OldPages/OrganizationsPage.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/patients",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dictionary/patients",
        name: "patients",
        component: () => import("@/views/pages/CRUD/PatientsCRUDPage.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/dictionary/payers",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dictionary/payers",
        name: "payers",
        component: () => import("@/views/pages/CRUD/PayerCRUDPage.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/dictionary/patientPayers",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dictionary/patientPayers",
        name: "payers",
        component: () => import("@/views/pages/CRUD/PatientPayerCRUDPage.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/dictionary/providers",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dictionary/providers",
        name: "providers",
        component: () => import("@/views/pages/CRUD/ProviderCRUDPage.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/dictionary/facilities",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dictionary/facilities",
        name: "facilities",
        component: () => import("@/views/pages/CRUD/FacilityCRUDPage.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/dictionary/rehabs",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dictionary/rehabs",
        name: "rehabs",
        component: () => import("@/views/pages/CRUD/RehabCRUDPage.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/dictionary/encounters",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dictionary/encounters",
        name: "encounters",
        component: () => import("@/views/pages/CRUD/EncounterCRUDPage.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/patientsList",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/patientsList",
        name: "patientsList",
        component: () => import("@/views/pages/OldPages/PatientsListPage.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/ecountersList",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/ecountersList",
        name: "ecountersList",
        component: () => import("@/views/pages/OldPages/EncounterListPage.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/claimsList",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/claimsList",
        name: "claimsList",
        component: () => import("@/views/pages/OldPages/ClaimsListPage.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/patientPage",
    component: () => import("@/layout/Layout.vue"),
    props: true,
    children: [
      {
        path: "/patientPage",
        name: "patientPage",
        component: () => import("@/views/pages/OldPages/PatientPage.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/",
    redirect: "/encounterPage",
    component: () => import("@/layout/Layout.vue"),
    props: true,
    children: [
      {
        path: "/encounterPage",
        name: "encounterPage",
        component: () => import("@/views/pages/OldPages/EncounterPage.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/",
    redirect: "/waystarFieldsPage",
    component: () => import("@/layout/Layout.vue"),
    props: true,
    children: [
      {
        path: "/waystarFieldsPage",
        name: "waystarFieldsPage",
        component: () => import("@/views/pages/OldPages/WaystarFormPage.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/",
    redirect: "/batchQueuePage",
    component: () => import("@/layout/Layout.vue"),
    props: true,
    children: [
      {
        path: "/batchQueuePage",
        name: "batchQueuePage",
        component: () => import("@/views/pages/OldPages/BatchQueuePreview.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/",
    redirect: "/claimsBatchPage",
    component: () => import("@/layout/Layout.vue"),
    props: true,
    children: [
      {
        path: "/claimsBatchPage",
        name: "claimsBatchPage",
        component: () => import("@/views/pages/OldPages/ClaimsBatchPage.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/open-id-login",
        component: () => import("@/views/crafted/authentication/open-id/SingIn.vue"),
        props: true,
      },
      {
        path: "/callback",
        component: () => import("@/views/crafted/authentication/open-id/AuthCallback.vue"),
        props: true,
      },
      {
        path: "/logout",
        component: () => import("@/views/crafted/authentication/open-id/LogOut.vue"),
        props: true,
      },
      {
        path: "/authcallback",
        component: () => import("@/views/crafted/authentication/basic-flow/CallBack.vue"),
        props: true,
      },
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/devtools/devconfig",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/devtools/devconfig",
        name: "devconfig",
        component: () => import("@/views/pages/DevTools/DevConfig.vue"),
      },
    ],
  },
  {
    path: "/devtools/devclaims",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/devtools/devclaims",
        name: "devclaims",
        component: () => import("@/views/pages/DevTools/DevClaims.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from) {
    // Skip if destination full path has query parameters and differs in no other way from previous
    if (from && Object.keys(to.query).length) {
      if (to.fullPath.split("?")[0] == from.fullPath.split("?")[0]) return;
    }
    // Handle scroll
  },
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    //window.scrollTo(0, 0);
  }, 100);
});

export default router;
