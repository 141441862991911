import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router/clean";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

//import Datepicker from "vue3-datepicker";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import DateComponent from "@/components/ABilling/DateComponent.vue";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";
import HourComponent from "@/components/ABilling/TimeComponent.vue";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import Maska from "maska";
import VueClipboard from "vue3-clipboard";
import Popper from "vue3-popper";
import VueUploadComponent from "vue-upload-component";
import { mask } from "maska";

import "@/core/plugins/prismjs";
import "bootstrap";
import moment from "moment";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(Maska);
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
});

app.component("datepicker", Datepicker);
app.component("DateComponent", DateComponent);
app.component("DateFloatComponent", DateFloatComponent);
app.component("HourComponent", HourComponent);
app.component("Popper", Popper);
app.component("VueNumberInput", VueNumberInput);
app.component("file-upload", VueUploadComponent);

app.config.globalProperties.$moment = moment;
app.config.globalProperties.$filters = {
  getPhone(value) {
    if (value) return mask(value, "(###)-###-####", undefined);
    return "";
  },
  getBool(value) {
    if (value == true) return "Yes";
    return "No";
  },
  getDateString(date) {
    if (date) return moment.utc(date).format("MM/DD/yyyy");
    return "no date";
  },
  getDateTimeString(date) {
    if (date) return moment(date).format("MM/DD/yyyy hh:mm A");
    return "no date";
  },
  getDateTimeDateOnlyString(date) {
    if (date) return moment(date).format("MM/DD/yyyy");
    return "no date";
  },
  getCurrency(number, negative: boolean | null) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    if (negative) {
      if (number >= 0) {
        return formatter.format(number);
      } else {
        return "(" + formatter.format(-1 * number) + ")";
      }
    }
    return formatter.format(number);
  },
  getNumber(number) {
    return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(
      number
    );
  },
};
//app.use(moment);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
