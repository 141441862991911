import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "form-floating d-flex flex-column-fluid" }
const _hoisted_2 = {
  key: 0,
  for: "date"
}
const _hoisted_3 = {
  key: 0,
  class: "fa-solid fa-asterisk ab-required-fields"
}
const _hoisted_4 = {
  key: 1,
  class: "disablebg",
  for: "date"
}
const _hoisted_5 = {
  key: 0,
  class: "fa-solid fa-asterisk ab-required-fields"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_datepicker = _resolveComponent("datepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_datepicker, {
      id: "date",
      enableTimePicker: false,
      startTime: _ctx.startTime,
      modelValue: _ctx.date,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
        _ctx.onChange
      ],
      minDate: _ctx.minDate,
      maxDate: _ctx.maxDate,
      textInput: "",
      class: _normalizeClass(["form-select billing-floating-multiselect", _ctx.classes]),
      utc: "preserve",
      disabled: _ctx.disabled
    }, null, 8, ["startTime", "modelValue", "minDate", "maxDate", "onUpdate:modelValue", "class", "disabled"]),
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("i", _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.disabled)
      ? (_openBlock(), _createElementBlock("label", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("i", _hoisted_5))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}