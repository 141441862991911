const ACCESS_TOKEN_KEY = "access_token" as string;
const ID_TOKEN_KEY = "id_token" as string;
const ID_ORG_KEY = "id_org" as string;
const ID_REHAB_KEY = "id_rehab" as string;
const ID_USER = "id_user" as string;
const ID_EXPIRES_AT = "expires_at" as string;

export const getExpiresAt = (): string | null => {
  return window.localStorage.getItem(ID_EXPIRES_AT);
};

export const saveExpiresAt = (expires_at: any): void => {
  window.localStorage.setItem(ID_EXPIRES_AT, JSON.stringify(expires_at));
};

export const getUser = (): any | null => {
  const userStr = window.localStorage.getItem(ID_USER);
  if (userStr) {
    return JSON.parse(userStr);
  }
  return null;
};

export const saveUser = (user: any): void => {
  window.localStorage.setItem(ID_USER, JSON.stringify(user));
};

export const getRehab = (): string | null => {
  return window.localStorage.getItem(ID_REHAB_KEY);
};


export const saveRehab = (rehab: string): void => {
  window.localStorage.setItem(ID_REHAB_KEY, rehab);
};

/**
 * @description get token form localStorage
 */
export const getOrganization = (): string | null => {
  return window.localStorage.getItem(ID_ORG_KEY);
};

/**
 * @description save org into localStorage
 * @param org: string
 */
export const saveOrganization = (organizationId: string): void => {
  window.localStorage.setItem(ID_ORG_KEY, organizationId);
};
/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

/**
 * @description get token form localStorage
 */
export const getIdToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
};
/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveIdToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export default {
  getToken,
  getIdToken,
  saveToken,
  saveIdToken,
  destroyToken,
  getOrganization,
  saveOrganization,
};
