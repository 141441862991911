import ApiService from "@/core/services/ApiService";
import JwtService, { saveUser } from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { UserManager } from "oidc-client-ts";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: string[];
  user: User | null;
  isAuthenticated: boolean;
}

export function getManager() {
  const odicClientSettings = {} as any;
  odicClientSettings.client_id = process.env.VUE_APP_CLIENT_ID;
  odicClientSettings.authority = process.env.VUE_APP_ODIC_AUTHORITY;
  odicClientSettings.redirect_uri = window.location.origin + "/#/callback";
  odicClientSettings.post_logout_redirect_uri =
    window.location.origin + "/#/open-id-login";
  odicClientSettings.response_type = "code";
  //odicClientSettings.response_mode = "query";
  odicClientSettings.scope =
    "openid profile https://authorization.allevasoft.com/api:read https://authorization.allevasoft.com/api:write https://authorization.allevasoft.com/api:delete";
  odicClientSettings.filterProtocolClaims = true;
  odicClientSettings.loadUserInfo = true;
  odicClientSettings.getclaimsfromuserinfoendpoint = true;
  odicClientSettings.automaticSilentRenew = false;
  const manager = new UserManager(odicClientSettings);
  return manager;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = null as User | null;
  expires_at = null as any;
  id_token = "" as string | null;
  userManager = null as UserManager | null;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUserManager(): UserManager | null {
    return this.userManager;
  }

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User | null {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): string[] {
    return this.errors;
  }

  get getIdToken(): string | null {
    return this.id_token;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }
  @Mutation
  [Mutations.SET_USER_MANAGER](manager) {
    this.userManager = manager;
  }
  @Mutation
  [Mutations.SET_AUTH](authResponse) {
    this.isAuthenticated = true;
    this.user = authResponse.profile;
    this.errors = [];
    this.expires_at = authResponse.expires_at;
    this.id_token = authResponse.id_token;
    JwtService.saveToken(authResponse.access_token);

    JwtService.saveIdToken(authResponse.id_token);

  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user.profile;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    if (this.user) this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    this.expires_at = null;
    this.id_token = null;
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("api/demoAuth/login", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        if (data) saveUser(data);
      })
      .catch(({ response }) => {
        // TODO THIS AUTH -
        // ACTIVATED SIMPLE TABE BASED AUTH
        // this.context.commit(Mutations.SET_AUTH, "");
        this.context.commit(Mutations.SET_ERROR, response.data.messages);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
    saveUser(null);
    ApiService.setUserHeader(null);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.query("api/DemoAuth/TestAuth", {
        params: { token: JwtService.getToken() },
      })
        .then(({ data }) => {
          if (!data) {
            this.context.commit(Mutations.SET_ERROR, []);
            this.context.commit(Mutations.PURGE_AUTH);
            saveUser(null);
            ApiService.setUserHeader(null);
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, []);
          this.context.commit(Mutations.PURGE_AUTH);
          saveUser(null);
          ApiService.setUserHeader(null);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
      saveUser(null);
      ApiService.setUserHeader(null);
    }
  }
}
