import ApiService from "@/core/services/ApiService";
import { getUser, saveOrganization } from "@/core/services/JwtService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Organization } from "../../modules/organization/organization.model";

@Module
export default class OrganizationsModule extends VuexModule {
  organizations = [] as Organization[];
  currentOrganization = {} as Organization;
  rehabs: any;
  currentRehab: any;

  get selectedRehab(): any {
    return this.currentRehab;
  }

  get allRehabs(): [] {
    return this.rehabs;
  }

  get allOrganizations(): Organization[] {
    return this.organizations;
  }

  get organization(): Organization {
    return this.currentOrganization;
  }

  @Mutation
  ["SETORGANIZATION"](organization) {
    this.currentOrganization = organization;
  }

  @Mutation
  ["SETCURRENTREHAB"](rehab) {
    this.currentRehab = rehab;
  }

  @Mutation
  ["SETREHABS"](rehabs) {
    this.rehabs = rehabs;
  }

  @Mutation
  ["SETORGANIZATIONS"](organizations) {
    this.organizations = organizations;
  }

  @Action
  ["GETORGANIZATIONS"]() {
    return ApiService.get("api/tenants").then((res) => {
      this.context.commit("SETORGANIZATIONS", res.data);
    });
  }

  @Action
  ["GETCURRENTORGANIZATION"]() {
    return ApiService.get("api/tenants/getCurrentOrganization").then((res) => {
      this.context.commit("SETORGANIZATION", res.data);
      saveOrganization(res.data.id);
    });
  }

  @Action
  ["GETORGANIZATIONSFORUSER"]() {
    const strUser = getUser();
    if (strUser) {
      const user = JSON.parse(strUser);
      return ApiService.post("api/tenants/GetListTorUser", {
        login: user?.login,
      } as any).then((res) => {
        this.context.commit("SETORGANIZATIONS", res.data);
      });
    }
  }

  @Action
  ["GETALLREHABS"]() {
    return ApiService.get("api/v1/Rehabs/getAll").then((res) => {
      this.context.commit("SETREHABS", res.data);
    });
  }
}
