import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_datepicker = _resolveComponent("datepicker")!

  return (_openBlock(), _createBlock(_component_datepicker, {
    modelValue: _ctx.time,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.time) = $event)),
      _ctx.onChange
    ],
    textInput: "",
    timePicker: ""
  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
}